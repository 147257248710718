import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import store from '../store'

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home
	},
	{
		path: "/about",
		name: "About",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/About.vue")
	},
	{
		path: "/signin",
		name: "SignIn",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/SignIn.vue")
	},
	{
		path: "/checkauth",
		name: "CheckAuth",
		meta: {
			requires_auth: false
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/CheckAuth.vue")
	},
	{
		path: "/annual-reports",
		name: "AnnualRepors",
		meta: {
      requires_auth: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/AnnualReports.vue")
	},
	{
		path: "/annual-report-list",
		name: "AnnualReportList",
		meta: {
			requires_auth: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "annual-report-list" */ "../views/AnnualReportList.vue")
	},
	{
		path: "/violations",
		name: "Violations",
		meta: {
      requires_auth: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "violations" */ "../views/Violations.vue")
	},
	{
		path: "/portal-users",
		name: "PortalUsers",
		meta: {
      requires_auth: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "portal-users" */ "../views/PortalUsers.vue")
	},
	{
		path: "/manage-portal-users",
		name: "ManagePortalUsers",
		meta: {
      requires_auth: true
		},
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "manage-portal-users" */ "../views/ManagePortalUsers.vue")
	},
	{
		path: "/support-requests",
		name: "SupportRequests",
		meta: {
      requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "support-requests" */ "../views/SupportRequests.vue")
	},
	{
		path: "/sample-plans",
		name: "SamplePlans",
		meta: {
      requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "sample-plans" */ "../views/SamplePlans.vue")
	},
	{
    path: "/sample-plan-review/:plan_id",
		name: "SamplePlanReview",
		meta: {
      requires_auth: true
		},
		component: () =>
			import(/* webpackChunkName: "sample-plan-points" */ "../views/SamplePlanReview.vue")
	},

]

const router = new VueRouter({
	routes
})

router.beforeEach((to, from, next) => {
  console.log('router beforeEach ' + to.path)
	if (to.matched.some((record) => record.meta.requires_auth)) {
		if (store.getters.signed_in) {
			next()
			return
		}
    store.commit("visit_path", to.path)
		next("/checkauth")
	} else {
		next()
	}
})

export default router
