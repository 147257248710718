<template>
  <div id="app">
    <b-modal
      id="modal-update"
      ok-title="Update Portal"
      title="Update Available"
      @ok="refreshApp"
    >
      <p class="text-center">
        An update to the MS BPWS Office Portal is available.
      </p>
    </b-modal>

    <b-container
      id="main-container"
      fluid
      class="main-container p-0 min-vh-100 d-flex flex-column"
    >
      <b-row no-gutters>
        <b-col>
          <b-navbar toggleable="lg" type="dark" variant="primary">
            <b-navbar-brand to="/">Office Portal</b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-item to="/sample-plans">Sample Plans</b-nav-item>
                <b-nav-item to="/annual-reports">Annual Reports</b-nav-item>
                <b-nav-item to="/violations">Violations</b-nav-item>
                <b-nav-item to="/portal-users">Portal Users</b-nav-item>
              </b-navbar-nav>
              <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right>
                  <!-- Using 'button-content' slot -->
                  <template #button-content>
                    <em>{{ username }}</em>
                  </template>
                  <b-dropdown-item v-if="signed_in" @click="signout"
                    >Sign Out</b-dropdown-item
                  >
                  <b-dropdown-item to="signin" v-if="!signed_in"
                    >Sign In</b-dropdown-item
                  >
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </b-col>
      </b-row>
      <b-row no-gutters class="mb-auto">
        <b-col>
          <b-container fluid class="text-center mt-3">
            <router-view />
          </b-container>
        </b-col>
      </b-row>

      <b-row no-gutters align-v="end">
        <b-col>
          <footer class="page-footer bg-light text-center small">
            <div class="footer-copyright py-3">
              &copy; 2020-2021 | MSDH BPWS Office Portal
            </div>
          </footer>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // for update
      refreshing: false,
      registration: null,
      updateExists: false
    }
  },
  created() {
    // software udpate
    console.log("add listener for swUpdated")
    document.addEventListener("swUpdated", this.updateAvailable, { once: true })
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      console.log("controllerchange event")
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      console.log("reloading")
      window.location.reload()
    })
    // check login
    this.$store
      .dispatch("checkSession")
      .then(() => {
        console.log("check session complete")
      })
      .catch((err) => {
        console.log(this.$store.status)
        console.log(err)
      })
  },
  computed: {
    signed_in() {
      return this.$store.getters.signed_in
    },
    username() {
      return this.$store.state.user || "Guest"
    }
  },
  methods: {
    signout: function() {
      console.log("called signout")
      this.$store.dispatch("signout").then(() => {
        if (this.$route.path !== "/signin") {
          this.$router.push("/signin")
        }
      })
    },
    refreshApp() {
      console.log("called refreshApp")
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      console.log("SKIP_WAITING")
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" })
    },
    updateAvailable(event) {
      console.log("update available event")
      this.registration = event.detail
      this.updateExists = true
      this.$bvModal.show("modal-update")
    }
  },
}
</script>
