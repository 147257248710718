import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"

Vue.use(Vuex)

// https://www.digitalocean.com/community/tutorials/handling-authentication-in-vue-using-vuex

export default new Vuex.Store({
  state: {
    status: "",
    user: "",
    visit_path: "",
    sample_plans_list_status: "N",
    sample_plans_list_pws_id: "",
  },
  mutations: {
    auth_request(state) {
      state.status = "loading"
    },
    auth_success(state, user) {
      state.status = "success"
      state.user = user
    },
    auth_error(state) {
      state.status = "error"
    },
    signout(state) {
      state.status = ""
      state.user = ""
      state.visit_path = ""
    },
    visit_path(state, visit_path) {
      state.visit_path = visit_path
    },
    set_sample_plans_list_status(state, status) {
      state.sample_plans_list_status = status
    },
    set_sample_plans_list_pws_id(state, pws_id) {
      state.sample_plans_list_pws_id = pws_id
    },
  },
  actions: {
    checkSession({ commit }) {
      console.log("checking session")
      return new Promise((resolve, reject) => {
        commit("auth_request")
        const url = process.env.VUE_APP_API_URI + "/check-session"
        axios({
          url: url,
          method: "GET"
        })
          .then((resp) => {
            if (resp.data.authenticated) {
              console.log("found session")
              commit("auth_success", resp.data.name)
              resolve(resp)
            } else {
              commit("auth_error")
              reject("no auth from server")
            }
          })
          .catch((err) => {
            commit("auth_error")
            reject(err)
          })
      })
    },
    signin({ commit }, user) {
      console.log("signing in")
      return new Promise((resolve, reject) => {
        commit("auth_request")
        const url = process.env.VUE_APP_API_URI + "/signin"
        console.log(url)
        axios({
          url: url,
          data: user,
          method: "POST"
        })
          .then((resp) => {
            //const token = resp.data.token
            console.log(resp)
            if (resp.data.authenticated) {
              //localStorage.setItem("token", token)
              //axios.defaults.headers.common["Authorization"] = token
              //commit("auth_success", token, user)
              commit("auth_success", resp.data.name)
              resolve(resp)
            } else {
              commit("auth_error")
              reject()
            }
          })
          .catch((err) => {
            commit("auth_error")
            //localStorage.removeItem("token")
            reject(err)
          })
      })
    },
    signout({ commit }) {
      //return new Promise((resolve) => {
      return new Promise((resolve, reject) => {
        const url = process.env.VUE_APP_API_URI + "/signout"
        axios({
          url: url,
          method: "GET"
        })
          .then(() => {
            commit("signout")
            resolve()
          })
          .catch((err) => {
            commit("error signing out")
            reject(err)
          })
      })
    }
  },
  getters: {
    signed_in: (state) => !!state.user,
    auth_status: (state) => state.status,
    visit_path: (state) => state.visit_path
  },
  modules: {}
})
