<template>
  <div class="home">
    <b-container fluid>
      <b-row>
        <b-col>
          <b-card title="Menu" class="text-left">
            <b-card-text>
              <ul>
                <li>
                  <strong>
                    <b-link to="sample-plans">Sample Plans</b-link>
                  </strong>
                </li>
                <li>
                  <strong>
                    <b-link to="annual-reports">Annual Reports</b-link>
                  </strong>
                </li>
                <li>
                  <strong>
                    <b-link to="violations">Violations</b-link>
                  </strong>
                </li>
                <li>
                  <strong>
                    <b-link to="portal-users">Portal Users</b-link>
                  </strong>
                </li>
              </ul>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home"
}
</script>
